<!--
 Name: ContactForm.vue
 Description: Contact form component.
 Created by: Milena Malysheva on 2020-09-08
 Last edited by: Lokeswari Madhusudhana on 2024-11-24
-->

<template>
  <v-form
    v-model="valid"
    ref="form"
    lazy-validation
    class="darkGrey--text"
  >

    <!-- Field: Topic -->
    <!-- contact form page only -->
    <v-select
      :label="$t('contactForm.subjectField.placeholder')"
      :items="subjects"
      v-model="subject"
      :rules="subjectRules"
      return-object
      v-if="!newsletterOnly"
      solo
      flat
    />

    <!-- Field: name -->
    <!-- contact form page only -->
    <v-text-field
      :label="$t('contactForm.nameField.placeholder')"
      v-model="name"
      :rules="nameRules"
      v-if="!newsletterOnly"
      light
      solo
      flat
    >
    </v-text-field>

    <!-- Field: email -->
    <v-text-field
      :label="$t('contactForm.emailField.placeholder')"
      v-model="email"
      :rules="emailRules"
      light
      solo
      flat
    >
    </v-text-field>

    <v-textarea
      :label="$t('contactForm.messageField.placeholder')"
      v-model="message"
      :rules="messageRules"
      light
      solo
      flat
    >
    </v-textarea>

    <!-- data protection checkbox -->
    <v-checkbox
      v-model="dataCheckbox"
      :rules="dataCheckboxRules"
      class="checkbox-aligned-label checkbox-text mt-1"
      color="darkGrey"
    >
      <div slot="label" class="ml-1 darkGrey--text">
        <p class="text-uppercase mb-1">
          {{ $t("contactForm.dataCheckbox.text") }}
        </p>
        <i18n
          path="contactForm.dataCheckbox.explanation"
          tag="p"
          class="mb-0"
          @click.stop=""
        >
          <template #data-protection-link>
            <router-link
              to="/data"
              class="darkGrey--text"
              target="_blank"
            >{{ $t("contactForm.dataCheckbox.dataProtectionLink")}}</router-link>
          </template>
        </i18n>
      </div>
    </v-checkbox>

    <!-- required information note -->
    <!-- shown here on the landing page -->
    <p
      class="required-info-note mb-0 mt-2"
      v-if="newsletterOnly"
    >
      {{ $t("contactForm.requiredInfo") }}
    </p>

    <!-- submit button -->
    <v-row justify="center" no-gutters>
      <v-btn
        elevation="2"
        large
        width="100%"
        height="60"
        class="my-3 primary font-weight-medium"
        @click="submitForm"
        :disabled="!valid"
      >
        {{ newsletterOnly ? $t("contactForm.subscribeBtn") : $t("contactForm.sendBtn") }}
      </v-btn>
    </v-row>

    <!-- unsubscribe note -->
    <!-- landing page only -->
    <i18n
      path="contactForm.unsubscribeNote"
      tag="p"
      class="checkbox-text"
      v-if="newsletterOnly"
    >
      <template #unsubscribe-link>
        <a
          :href="`https://unsubscribe.newsletter2go.com/form.html?n2g=${$t('contactForm.newsletter2go.unsubscribeCode')}`"
          class="darkGrey--text"
          @click.stop=""
          target="_blank"
        >{{ $t("contactForm.unsubscribeNewsletterLink") }}</a>
      </template>
    </i18n>

    <!-- required information note -->
    <!-- shown here on the contact page -->
    <p
      class="required-info-note"
      v-if="!newsletterOnly"
    >
      {{ $t("contactForm.requiredInfo") }}
    </p>

    <!-- contact form info dialog -->
    <v-dialog v-model="dialog" max-width="500px">
      <v-card class="pa-3">
        <v-card-title class="mb-0">
          {{ dialogContent.title }}
        </v-card-title>
        <v-card-text class="mt-0 grey--text text--darken-1">
          {{ dialogContent.text }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="mr-3 mb-2"
            depressed
            @click="dialog = false"
            min-width="25%"
          >
            {{ $t("contactForm.dialog.closeBtn") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-form>
</template>

<script>
  import axios from "axios";
  import qs from 'qs';

  export default {
    props: {
      newsletterOnly: Boolean
    },
    data: vm => ({
      valid: false,
      subjects: [],
      subject: null,
      subjectRules: [v => !!v || vm.$t("contactForm.subjectField.validation")],
      name: "",
      nameRules: [v => !!v || vm.$t("contactForm.nameField.validation")],
      email: "",
      emailRules: [
        v => !!v || vm.$t("contactForm.emailField.validation.empty"),
        v => /^\S+@\S+\.\S+$/.test(v) || vm.$t("contactForm.emailField.validation.invalid")
      ],
      message: "",
      messageRules: [v => !!v || vm.$t("contactForm.messageField.validation")],
      newsletterCheckbox: false,
      dataCheckbox: false,
      dataCheckboxRules: [v => v || vm.$t("contactForm.dataCheckbox.validation")],
      dialog: false,
      dialogContent: {}
    }),
    methods: {
      submitForm () {
        if (!this.$refs.form.validate()) {
          return;
        }
        (this.newsletterOnly ? this.subscribeToNewsletter() : this.sendMessage())
          .then(() => {
            this.dialogContent = this.$t("contactForm.dialog.success");
            this.$refs.form.reset();
          })
          .catch(() => {
            this.dialogContent = this.$t("contactForm.dialog.error");
          })
          .finally(() => {
            this.dialog = true
          })
      },
      async sendMessage() {
        const formData = {
          Name: this.name,
          Email: this.email,
          Message: this.message,
          // defaultOption ("Allgemein") => 'gedaechtnis-erforschen'
          Note: 'gedaechtnis-erforschen',
          Date: this.formattedDate
        }
        const formDataEncoded = qs.stringify(formData)
        return this.$axios.post("/Nodemailer/SendEmail", formDataEncoded)
          .then(() => {
            if (this.newsletterCheckbox) {
              return this.subscribeToNewsletter();
            }
          })
      },
      subscribeToNewsletter() {
        return axios({
          method: 'post',
          baseURL: 'https://api.newsletter2go.com/',
          url: `forms/submit/${this.$t("contactForm.newsletter2go.subscribeCode")}`,
          data: {
            recipient: {
              email: this.email
            }
          }
        })
      }
    },
    mounted() {
      this.subjects = [
        // default option
        {
          text: this.$t("contactForm.subjectField.defaultOption")
        }
      ]
      if (this.$route.params.projectId !== undefined) {
        this.subject = this.subjects.find(project => project.id === this.$route.params.projectId)
      }
    },
    computed: {
      formattedDate () {
        const currentDate = new Date()
        const day = currentDate.getDate().toString().padStart(2, "0");
        const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
        const year = currentDate.getFullYear();
        return `${day}.${month}.${year}`;
      }
    }
  };
</script>

<style scoped>
  /* contact form font classes */
  .required-info-note {
    font-size: 14px;
  }
  .checkbox-text {
    font-size: 16px;
  }

  /* overriding vuetify styles */
  ::v-deep .checkbox-aligned-label .v-input__slot {
    align-items: start;
  }
</style>